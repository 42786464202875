<template>
    <div>
        <page-header></page-header>
        <div class="content-c">
            <div class="content-center">
                <div>
                    <div @click="tapClick(1)" :class="{activat:isActivat == 1}">{{$t('pageBottom.aboutUs')}}</div>
                    <div @click="tapClick(2)" :class="{activat:isActivat == 2}">{{$t('pageBottom.clause')}}</div>
                    <div @click="tapClick(3)" :class="{activat:isActivat == 3}">{{$t('pageBottom.privacy')}}</div>
                    <div @click="tapClick(4)" :class="{activat:isActivat == 4}">{{$t('pageBottom.safety')}}</div>
                    <div @click="tapClick(5)" :class="{activat:isActivat == 5}">{{$t('pageBottom.contactUs')}}</div>
                    <div @click="tapClick(6)" :class="{activat:isActivat == 6}">{{$t('pageBottom.center')}}</div>
                    <div @click="tapClick(7)" :class="{activat:isActivat == 7}">{{$t('pageBottom.refund')}}</div>
                </div>
                <div v-if="isActivat == 1">
                    <div class="content-title">{{$t('content.title_01')}}</div>
                    <div class="content-stitle">{{$t('content.text_01')}} Now the site is owned and operated by{{ other_page_remark }}</div>
                    <img class="content-img" :src="img_01" alt="">
                    <div class="content-title">{{$t('content.title_02')}}</div>
                    <div class="content-stitle">{{$t('content.text_01')}}</div>
                    <img class="content-img" :src="img_02" alt="">
                    <div class="content-title">{{$t('content.title_03')}}</div>
                    <div class="content-stitle">{{$t('content.text_03')}}</div>
                </div>
                <div v-if="isActivat == 2">
                    <div class="content-stitle">{{$t('content.text_04')}} Now the site is owned and operated by{{ other_page_remark }}</div>
                    <div class="content-title">{{$t('content.title_04')}}</div>
                    <div class="content-title">{{$t('content.title_05')}}</div>
                    <div class="content-stitle">{{$t('content.text_05')}}</div>
                    <div class="content-title">{{$t('content.text_06')}}</div>
                    <div class="content-stitle">{{$t('content.text_06')}}</div>
                    <div class="content-title">{{$t('content.title_07')}}</div>
                    <div class="content-stitle">{{$t('content.text_07')}}</div>
                    <div class="content-title">{{$t('content.title_08')}}</div>
                    <div class="content-stitle">{{$t('content.text_08')}}</div>
                    <div class="content-title">{{$t('content.title_09')}}</div>
                    <div class="content-stitle">{{$t('content.text_09')}}</div>
                    <div class="content-title">{{$t('content.text_10')}}</div>
                    <div style="padding-left:30px">
                        <div class="content-title">{{$t('content.text_10_1')}}</div>
                        <div class="content-stitle">{{$t('content.text_10_2')}}</div>
                        <div class="content-title">{{$t('content.text_10_3')}}</div>
                        <div class="content-stitle">{{$t('content.text_10_4')}}</div>
                        <div class="content-title">{{$t('content.text_10_5')}}</div>
                        <div class="content-stitle">{{$t('content.text_10_6')}}</div>
                    </div>
                    <div class="content-title">{{$t('content.title_11')}}</div>
                    <div class="content-stitle">{{$t('content.text_11')}}</div>
                    <div class="content-title">{{$t('content.title_12')}}</div>
                    <div class="content-stitle">{{$t('content.text_12')}}</div>
                    <div class="content-title">{{$t('content.title_13')}}</div>
                    <div class="content-stitle">{{$t('content.text_13')}}</div>
                    <div class="content-title">{{$t('content.title_14')}}</div>
                    <div class="content-stitle">{{$t('content.text_14')}}</div>
                    <div class="content-title">{{$t('content.title_15')}}</div>
                    <div class="content-stitle">{{$t('content.text_15')}}</div>
                </div>
                <div v-if="isActivat == 3">
                    <div class="content-stitle">{{$t('content.text_16_c')}} Now the site is owned and operated by{{ other_page_remark }}</div>
                    <div class="content-title">{{$t('content.title_16')}}</div>
                    <div class="content-stitle">{{$t('content.text_16')}}</div>
                    <div class="content-title">{{$t('content.title_17')}}</div>
                    <div class="content-stitle">{{$t('content.text_17')}}</div>
                    <div class="content-title">{{$t('content.title_18')}}</div>
                    <div class="content-stitle">{{$t('content.text_18')}}</div>
                    <div class="content-title">{{$t('content.title_19')}}</div>
                    <div style="padding-left:20px">
                        <div class="content-stitle">{{$t('content.text_19_1')}}</div>
                        <div class="content-stitle">{{$t('content.text_19_2')}}</div>
                        <div class="content-stitle">{{$t('content.text_19_3')}}</div>
                        <div class="content-stitle">{{$t('content.text_19_4')}}</div>
                    </div>
                    <div class="content-title">{{$t('content.title_20')}}</div>
                    <div class="content-stitle">{{$t('content.text_20')}}</div>
                    <div class="content-title">{{$t('content.title_21')}}</div>
                    <div class="content-stitle">{{$t('content.text_21')}}</div>
                    <div class="content-title">{{$t('content.title_22')}}</div>
                    <div class="content-stitle">{{$t('content.text_22')}}</div>
                    <div class="content-title">{{$t('content.title_23')}}</div>
                    <div class="content-stitle">{{$t('content.text_23')}}</div>
                </div>
                <div v-if="isActivat == 4">
                    <div class="content-stitle">{{$t('content.text_23_1')}}</div>
                    <div class="content-stitle">{{$t('content.text_23_2')}}</div>
                    <div class="content-stitle">{{$t('content.text_23_3')}}</div>
                    <div class="content-stitle">{{$t('content.text_23_4')}}</div>
                    <div class="content-stitle">{{$t('content.text_23_5')}}</div>
                    <div class="content-title">{{$t('content.title_21')}}</div>
                    <div class="content-stitle">{{$t('content.text_23_6')}}</div>
                    <div class="content-stitle">{{$t('content.text_23_7')}}</div>
                    <div class="content-stitle">{{$t('content.text_23_8')}}</div>
                    <div class="content-stitle">{{$t('content.text_23_9')}}</div>
                    <div class="content-stitle">{{$t('content.text_23_10')}}</div>
                </div>
                <div v-if="isActivat == 5">
                    <div class="content-title">{{$t('content.title_24')}}</div>
                    <div class="content-stitle">{{$t('content.text_24')}}</div>
                    <div class="content-title">{{$t('content.title_25')}}</div>
                    <div class="content-stitle">{{$t('content.text_25')}}</div>
                    <div class="content-title">{{$t('content.title_26')}}</div>
                    <div class="content-stitle">{{$t('content.text_26')}}</div>
                    <div class="content-title">{{$t('content.title_27')}}</div>
                    <div class="content-stitle">{{$t('content.text_27')}}</div>
                    <div class="content-title">{{$t('content.title_28')}}</div>
                    <div class="content-stitle">{{$t('content.text_28')}}</div>
                    <div class="content-title">{{$t('content.title_29')}}</div>
                    <div class="content-stitle">{{$t('content.text_29')}}</div>
                    <div class="content-title">{{$t('content.title_30')}}</div>
                    <div class="content-stitle">{{$t('content.text_30')}}</div>
                </div>
                <div v-if="isActivat == 6">
                    <!-- 
                    <div class="content-stitle">{{$t('content.text_31_1')}}</div>
                    <div class="content-stitle">{{$t('content.text_31_2')}}</div> 
                    -->
                    <div>Company:{{ contact_us_company }}</div>
                    <div>Address:{{ contact_us_address }}</div>
                    <div class="content-stitle">{{$t('content.text_31_3')}}</div>
                    <div class="content-stitle">{{$t('content.text_31_4')}}</div>
                </div>
                <div v-if="isActivat == 7">
                    <div class="content-title">{{$t('content.title_32')}}</div>
                    <div class="content-stitle">{{$t('content.text_32')}}</div>
                </div>
            </div>
        </div>
        <page-bottom></page-bottom>
    </div>
</template>

<script>
import PageHeader from '../components/pageHeader.vue'
import PageBottom from '../components/pageBottom.vue'
export default {
    components: {PageHeader,PageBottom},
    data() {
        return{
            isActivat: 1,
            img_01: require("../assets/images/us1.jpg"),
            img_02: require("../assets/images/us2.jpg"),
            contact_us_company: '',
            contact_us_address: '',
            other_page_remark: ''
        }
    },
    created(){
        this.isActivat = sessionStorage.number
    },
    mounted() {
        this.contact_us_company = contact_us_company
        this.contact_us_address = contact_us_address
        this.other_page_remark = other_page_remark
    },
    methods: {
        tapClick(num){
            this.isActivat = num
        }
    }
}
</script>

<style scoped>
.content-c{
    height: calc( 100vh - 184px );
    padding-top: 80px
}
.content-center{
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}
.content-center>div:nth-child(1){
    width: 18%;
    background: #fff;
    height: max-content;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, .1);
}
.content-center>div:nth-child(1)>div{
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #eee;
    font-size: 14px;
    color: #999;
    cursor: pointer;
}
.content-center>div:nth-child(2){
    width: calc( 80% - 80px );
    height: calc( 100vh - 248px );
    background: #fff;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, .1);
    padding: 20px 40px;
    overflow-y: scroll;
}
.activat{
    background: rgba(255, 0, 0, 1) !important;
    color: #fff !important; 
}
.content-title{
    font-weight: bold;
    font-size: 14px;
    color: #333;
    margin-bottom: 10px
}
.content-stitle{
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
    text-indent: 28px;
    line-height: 32px;
}
.content-img{
    display: block;
    margin: 20px auto
}
</style>